import React, { useState, useEffect } from "react";
import axios from "axios";

export default function Documents() {
  const [fileList, setFileList] = useState([]);
  const [spriteFileList, setSpriteFileList] = useState([]);
  const [tilesetFileList, setTilesetFileList] = useState([]); // New state for tileset files
  const [tilesetName, setTilesetName] = useState(""); // New state for tileset name
  const [uploading, setUploading] = useState(false);
  const [spriteUploading, setSpriteUploading] = useState(false);
  const [tilesetUploading, setTilesetUploading] = useState(false); // New state for tileset upload status
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [totalAutomations, setTotalAutomations] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [bulkDeleteQuery, setBulkDeleteQuery] = useState("");
  const [currentCategory, setCurrentCategory] = useState("2d_assets");
  const [currentType, setCurrentType] = useState("");
  const [currentStatus, setCurrentStatus] = useState("2d_processing");
  const [currentFormat, setCurrentFormat] = useState("");
  const [largeImageModalOpen, setLargeImageModalOpen] = useState(false);
  const [largeImageUrl, setLargeImageUrl] = useState("");

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFileList([selectedFile]);
    }
  };

  const handleSpriteFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setSpriteFileList([selectedFile]);
    }
  };

  const handleTilesetFileChange = (event) => { // New function for tileset file change
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setTilesetFileList([selectedFile]);
    }
  };

  const handleUpload = async () => {
    if (fileList.length === 0) {
      alert("Please select a ZIP file to upload.");
      return;
    }
    const formData = new FormData();
    formData.append("file", fileList[0]);
    setUploading(true);

    try {
      const response = await axios.post(
        "https://embed.axv.ai/api/automation/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 201) {
        alert("ZIP file uploaded successfully.");
        fetchTableData(currentPage);
      } else {
        alert("Failed to upload ZIP file.");
      }
    } catch (error) {
      console.error(error);
      alert("There was an error uploading the ZIP file.");
    } finally {
      setUploading(false);
      setFileList([]);
    }
  };

  const handleSpriteUpload = async () => {
    if (spriteFileList.length === 0) {
      alert("Please select a Spritesheet file to upload.");
      return;
    }
    const formData = new FormData();
    formData.append("file", spriteFileList[0]);
    setSpriteUploading(true);

    try {
      const response = await axios.post(
        "https://embed.axv.ai/api/automation/sprite",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 201) {
        alert("Spritesheet uploaded successfully.");
        fetchTableData(currentPage);
      } else {
        alert("Failed to upload Spritesheet.");
      }
    } catch (error) {
      console.error(error);
      alert("There was an error uploading the Spritesheet.");
    } finally {
      setSpriteUploading(false);
      setSpriteFileList([]);
    }
  };

  const handleTilesetUpload = async () => { // New function for uploading tileset file
    if (tilesetFileList.length === 0 || !tilesetName) {
      alert("Please select a Tileset file and provide a name.");
      return;
    }
    const formData = new FormData();
    formData.append("file", tilesetFileList[0]);
    formData.append("tileset_name", tilesetName);
    setTilesetUploading(true);

    try {
      const response = await axios.post(
        "https://embed.axv.ai/api/automation/tileset",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 201) {
        alert("Tileset uploaded successfully.");
        fetchTableData(currentPage);
      } else {
        alert("Failed to upload Tileset.");
      }
    } catch (error) {
      console.error(error);
      alert("There was an error uploading the Tileset.");
    } finally {
      setTilesetUploading(false);
      setTilesetFileList([]);
      setTilesetName("");
    }
  };

  const fetchTableData = async (page) => {
    setLoading(true);

    const queryParams = new URLSearchParams({
      category: currentCategory,
      page: page,
      limit: 20,
    });

    if (currentType) {
      queryParams.append("type", currentType);
    }

    if (currentStatus) {
      queryParams.append("status", currentStatus);
    }

    if (currentFormat) {
      queryParams.append("format", currentFormat);
    }

    try {
      const response = await fetch(
        `https://embed.axv.ai/api/automation?${queryParams.toString()}`
      );
      const result = await response.json();
      if (result.isSuccess) {
        setData(result.automationList);
        setCurrentPage(result.currentPage);
        setTotalItems(result.totalItems);
        setTotalAutomations(result.totalAutomations);
      } else {
        alert("Failed to fetch data.");
      }
    } catch (error) {
      console.error(error);
      alert("There was an error fetching the data.");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (_id) => {
    setLoading(true);
    try {
      const response = await axios.delete(
        `https://embed.axv.ai/api/automation/${_id}`
      );

      if (response.status === 200) {
        alert("File deleted successfully.");
        setData((prevData) => prevData.filter((item) => item._id !== _id));
      } else {
        alert("Failed to delete file.");
      }
    } catch (error) {
      console.error(error);
      alert("There was an error deleting the file.");
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page) => {
    fetchTableData(page);
  };

  const handleBulkDelete = async () => {
    if (!bulkDeleteQuery) {
      alert("Please enter a search query.");
      return;
    }

    try {
      const response = await axios.post(
        "https://embed.axv.ai/api/automation/bulk/delete",
        { text: bulkDeleteQuery }
      );

      if (response.status === 200) {
        alert(`Successfully removed ${response.data.updatedCount} files.`);
        fetchTableData(currentPage);
      } else {
        alert("Failed to perform bulk delete.");
      }
    } catch (error) {
      console.error(error);
      alert("There was an error performing bulk delete.");
    } finally {
      setIsModalOpen(false);
      setBulkDeleteQuery("");
    }
  };

  const handleCategoryChange = (newCategory) => {
    setCurrentCategory(newCategory);
    fetchTableData(1);
  };

  const handleTypeChange = (newType) => {
    setCurrentType(newType);
    fetchTableData(1);
  };

  const handleStatusChange = (newStatus) => {
    setCurrentStatus(newStatus);
    fetchTableData(1);
  };

  const handleFormatChange = (newFormat) => {
    setCurrentFormat(newFormat);
    fetchTableData(1);
  };

  const handleRowCategoryChange = async (newCategory, _id) => {
    try {
      const response = await axios.put(
        `https://embed.axv.ai/api/automation/${_id}`,
        { category: newCategory }
      );

      if (response.status === 200) {
        alert("Category updated successfully.");
        fetchTableData(currentPage);
      } else {
        alert("Failed to update category.");
      }
    } catch (error) {
      console.error(error);
      alert("There was an error updating the category.");
    }
  };

  const openLargeImageModal = (imageUrl) => {
    setLargeImageUrl(imageUrl);
    setLargeImageModalOpen(true);
  };

  const closeLargeImageModal = () => {
    setLargeImageModalOpen(false);
  };

  useEffect(() => {
    fetchTableData(1);
  }, []);

  return (
    <div style={{ backgroundColor: "black", padding: "20px", color: "white" }}>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <h2>Upload Documents (ZIP)</h2>
        <div>
          <span style={{ marginRight: "20px" }}>Total Automations: {totalAutomations}</span>
          <button onClick={() => setIsModalOpen(true)}>Bulk Clean</button>
        </div>
      </div>

      <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginBottom: "10px" }}>
        <select
          onChange={(e) => handleCategoryChange(e.target.value)}
          value={currentCategory}
          style={{ marginRight: "10px" }}
        >
          <option value="2d_assets">2D Assets</option>
          <option value="single_png_asset">Single PNG Asset</option>
          <option value="tileset">Tileset</option>
        </select>

        <select
          onChange={(e) => handleTypeChange(e.target.value)}
          value={currentType}
          style={{ marginRight: "10px" }}
        >
          <option value="">Select Type</option>
          <option value="character">Character</option>
          <option value="background">Background</option>
          <option value="icons">Icons</option>
          <option value="objects">Objects</option>
        </select>

        <select
          onChange={(e) => handleStatusChange(e.target.value)}
          value={currentStatus}
          style={{ marginRight: "10px" }}
        >
          <option value="2d_processing">2D Processing</option>
          <option value="2d_ready">2D Ready</option>
          <option value="2d_complete">2D Complete</option>
          <option value="single_png_image">Single PNG</option>
        </select>

        <select
          onChange={(e) => handleFormatChange(e.target.value)}
          value={currentFormat}
          style={{ marginRight: "10px" }}
        >
          <option value="">Select Format</option>
          <option value="spritesheet">Spritesheet</option>
          <option value="image">Image</option>
        </select>
      </div>

      {/* ZIP File Upload Area */}
      <input
        type="file"
        accept=".zip"
        onChange={handleFileChange}
        style={{ marginBottom: "10px" }}
      />
      <p>{fileList.length > 0 ? fileList[0].name : "Select ZIP File"}</p>

      <button
        onClick={handleUpload}
        disabled={fileList.length === 0 || uploading}
        style={{ marginBottom: "20px" }}
      >
        {uploading ? "Uploading" : "Start Upload"}
      </button>

      {/* Spritesheet Upload Area */}
      <div style={{ marginTop: "30px", paddingTop: "20px", borderTop: "1px solid white" }}>
        <h2>Upload Spritesheet</h2>
        <input
          type="file"
          accept=".zip"
          onChange={handleSpriteFileChange}
          style={{ marginBottom: "10px" }}
        />
        <p>{spriteFileList.length > 0 ? spriteFileList[0].name : "Select Spritesheet File"}</p>

        <button
          onClick={handleSpriteUpload}
          disabled={spriteFileList.length === 0 || spriteUploading}
          style={{ marginBottom: "20px" }}
        >
          {spriteUploading ? "Uploading" : "Start Upload"}
        </button>
      </div>

      {/* Tileset Upload Area */}
      <div style={{ marginTop: "30px", paddingTop: "20px", borderTop: "1px solid white" }}>
        <h2>Upload Tileset</h2>
        <input
          type="file"
          accept=".zip"
          onChange={handleTilesetFileChange}
          style={{ marginBottom: "10px" }}
        />
        <p>{tilesetFileList.length > 0 ? tilesetFileList[0].name : "Select Tileset File"}</p>
        <input
          type="text"
          placeholder="Enter Tileset Name"
          value={tilesetName}
          onChange={(e) => setTilesetName(e.target.value)}
          style={{ marginBottom: "10px", width: "100%" }}
        />

        <button
          onClick={handleTilesetUpload}
          disabled={tilesetFileList.length === 0 || tilesetUploading || !tilesetName}
          style={{ marginBottom: "20px" }}
        >
          {tilesetUploading ? "Uploading" : "Start Upload"}
        </button>
      </div>

      <h2>Files</h2>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <table border="1" style={{ width: "100%", color: "white" }}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Image</th>
              <th>Description</th>
              <th>Status</th>
              <th>Filename</th>
              <th>Category</th>
              <th>Type</th>
              <th>Format</th>
              <th>Subtype</th>
              <th>Width</th>
              <th>Height</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr key={item._id}>
                <td>{item.name}</td>
                <td>
                  <img src={item.file_url} alt="File" style={{ width: 150, cursor: "pointer" }} onClick={() => openLargeImageModal(item.file_url)} />
                  <button
                    style={{ marginLeft: "5px" }}
                    onClick={() => openLargeImageModal(item.file_url)}
                  >
                    i
                  </button>
                </td>
                <td>{item.description}</td>
                <td>{item.status}</td>
                <td>{item.filename}</td>
                <td>
                  <select
                    value={item.category}
                    onChange={(e) => handleRowCategoryChange(e.target.value, item._id)}
                  >
                    <option value="2d_assets">2D Assets</option>
                    <option value="single_png_asset">Single PNG Asset</option>
                  </select>
                </td>
                <td>{item.type || 'N/A'}</td>
                <td>{item.format || 'N/A'}</td>
                <td>{item.subtype || 'N/A'}</td>
                <td>{item.width || 'N/A'}</td>
                <td>{item.height || 'N/A'}</td>
                <td>
                  <button
                    style={{ color: "red" }}
                    onClick={() => handleDelete(item._id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <div style={{ marginTop: "20px", textAlign: "center" }}>
        <button
          onClick={() => handlePageChange(Math.max(currentPage - 1, 1))}
          disabled={currentPage <= 1}
        >
          Previous
        </button>
        <span style={{ margin: "0 10px" }}>Page {currentPage}</span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage * 20 >= totalItems}
        >
          Next
        </button>
        <div style={{ marginTop: "10px" }}>
          <input
            type="number"
            min="1"
            max={Math.ceil(totalItems / 20)}
            value={currentPage}
            onChange={(e) => handlePageChange(Number(e.target.value))}
            style={{ width: "50px", textAlign: "center" }}
          />
        </div>
      </div>

      {isModalOpen && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "20px",
            zIndex: 1000,
            color: "black",
            maxWidth: "80%",
            maxHeight: "80%",
            overflowY: "auto",
          }}
        >
          <h3>Bulk Delete Files</h3>
          <input
            type="text"
            placeholder="Enter text to search"
            value={bulkDeleteQuery}
            onChange={(e) => setBulkDeleteQuery(e.target.value)}
            style={{ width: "100%", marginBottom: "10px" }}
          />
          <button onClick={handleBulkDelete} style={{ marginRight: "10px" }}>
            Send
          </button>
          <button onClick={() => setIsModalOpen(false)}>Cancel</button>
        </div>
      )}

      {largeImageModalOpen && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "20px",
            zIndex: 1000,
            color: "black",
            maxWidth: "90%",
            maxHeight: "90%",
            overflow: "hidden",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)"
          }}
        >
          <button style={{ float: "right" }} onClick={closeLargeImageModal}>
            Close
          </button>
          <div style={{ overflow: "auto", height: "85vh" }}>
            <img src={largeImageUrl} alt="Large View" style={{ width: "100%", height: "auto" }} />
          </div>
        </div>
      )}
    </div>
  );
}