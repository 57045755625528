import {
  Input,
  message,
  Modal,
  Spin,
  Table,
  Tag,
  Button as BButton,
  InputNumber,
  Select,
  Button,
} from "antd";
import React, { useEffect, useState } from "react";
import TagsGroup from "../../components/TagsGroup";

export default function Automation() {
  const [automation, setAutomation] = useState();
  const [totalPages, setTotalPages] = useState();
  const [isChange, setIsChange] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [callApi, setCallApi] = useState(1);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState(""); // State for search query

  useEffect(() => {
    getAutomation();
  }, [pageNo, callApi, searchQuery]); // Added searchQuery to the dependency array

  const getAutomation = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `${process.env.REACT_APP_API}/automation?page=${pageNo}&limit=15&sort_by=${-1}&category=ai_3d&name=${searchQuery}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setAutomation(data.automationList);
          setTotalPages(data.totalPages);
        }
        setLoading(false);
        console.log(data);
      });
  };

  const saveChanges = (row, id, save) => {
    if (isChange || save) {
      setIsChange(false);

      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
        body: JSON.stringify(row),
      };
      fetch(`${process.env.REACT_APP_API}/automation/${id}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data && data.patchedData) {
            message.success("Updated");
          } else {
            message.error("Something went wrong");
          }
        });
    }
  };

  const changeField = (key, val, row) => {
    row[key] = val;
    const allRow = automation.map((m) => (m._id === row._id ? row : m));
    setAutomation(allRow);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 150,
      render: (text, row) => (
        <Input
          value={text}
          placeholder="Name"
          onChange={(e) => {
            changeField("name", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ name: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 250,
      render: (text, row) => (
        <Input
          value={text}
          placeholder="Description"
          onChange={(e) => {
            changeField("description", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ description: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "Prompt",
      dataIndex: "prompt",
      key: "prompt",
      width: 300, // Increased width
      render: (text, row) => (
        <Input.TextArea
          value={text}
          placeholder="Prompt"
          rows={3} // Multi-line input
          onChange={(e) => {
            changeField("prompt", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ prompt: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 150,
      render: (text, row) => (
        <Select
          value={text}
          placeholder="Status"
          onChange={(value) => {
            changeField("status", value, row);
            saveChanges({ status: value }, row._id, true);
          }}
          className={`${text === "ready" ? " yellow-drop" : ""} ${
            text === "error" ? " red-drop" : ""
          } ${text === "live" ? " green-drop" : ""}`}
          style={{ width: "120px" }}
          options={[
            {
              value: "ready",
              label: "Ready",
            },
            {
              value: "error",
              label: "Error",
            },
            {
              value: "live",
              label: "Live",
            },
          ]}
        />
      ),
    },
    {
      title: "Features",
      dataIndex: "features",
      key: "features",
      width: 150,
      render: (text, row) => (
        <Input
          value={text}
          placeholder="Features"
          onChange={(e) => {
            changeField("features", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ features: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },
    {
      title: "Synonyms",
      dataIndex: "synonyms",
      key: "synonyms",
      width: 150,
      render: (text, row) => (
        <TagsGroup
          tags={text ? text : []}
          color="#004aa3"
          onTagChange={(v) => {
            changeField("synonyms", v, row);
            saveChanges({ synonyms: v }, row._id, true);
          }}
        />
      ),
    },
    {
      title: "Query",
      dataIndex: "query",
      key: "query",
      width: 200,
      render: (text, row) => (
        <Input
          value={text}
          placeholder="Query"
          onChange={(e) => {
            changeField("query", e.target.value, row);
            setIsChange(true);
          }}
          onBlur={() => saveChanges({ query: text }, row._id)}
          className="hidden-textarea"
        />
      ),
    },
  ];

  const handleSearch = (e) => {
    setSearchQuery(e.target.value); // Update the search query state
  };

  return (
    <div className="container-c-on">
      <div className="flex-end">
        <Input
          placeholder="Search by Name"
          value={searchQuery}
          onChange={handleSearch}
          style={{ width: 300, marginBottom: 20 }}
        />
        <Button type="primary" size="small" onClick={() => setCallApi(callApi + 1)}>
          Reload
        </Button>
      </div>
      {loading ? <Spin /> : null}
      {automation ? (
        <Table
          className="scrol-tabel-c black-table pagination-white"
          pagination={{
            pageSize: 15,
            current: pageNo,
            total: totalPages * 15,
            onChange: (v) => {
              setAutomation();
              setPageNo(v);
            },
            size: "small",
            showSizeChanger: false,
          }}
          columns={columns}
          dataSource={automation}
        />
      ) : null}
    </div>
  );
}